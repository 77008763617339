import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    primary: {},
    icon: {},
  });

class Konversation extends Component {
  static propTypes = {
    konversation: PropTypes.any,
    selectKonversation: PropTypes.any.isRequired
  };

  constructor(props){
    super(props);


  }

  render() {
    const { classes, selectKonversation, konversation, account } = this.props;

    return (
      <MenuItem>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset
          primary={konversation == undefined ? "N/A" : konversation.name}
          onClick={() => {selectKonversation(account, konversation);}}
        />
      </MenuItem>
    );
  }
};

export default withStyles(styles)(Konversation);
