import React, { Component } from "react";
import PropTypes from "prop-types";
import Konversation from "../Konversation/Konversation";

export default class Konversationen extends Component {
  static propTypes = {
    konversationen: PropTypes.any,
    selectKonversation: PropTypes.any.isRequired
  };

  render() {
    const { konversationen, selectKonversation, account } = this.props;

    return (
      <div>
        {konversationen.map((konversation, key) => (
          <Konversation key={key} konversation={konversation} selectKonversation={selectKonversation} account={account}/>
        ))}
      </div>
    );
  }
}
