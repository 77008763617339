import React, { Component } from 'react';

import PropTypes from 'prop-types';

import "./Message.css";

export default class Message extends Component {
    static propTypes = {
        sender: PropTypes.any.isRequired,
        time: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        richtung: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired
    };

    render() {
        const {
            sender = { shortName: "NA" },
            time = (new Date()).toLocaleString(),
            status = 'gesendet',
            richtung = 'eingang',
            message = "Test"
        } = this.props;

        return (
            <div className={`Message ${status} ${richtung}`}>
                <div className="Header">{time}</div>
                <div className="Content">{message}</div>
            </div>
        )
    }
}