import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Konversationen from '../Konversationen/Konversationen';

import { MenuItem, ListItemText, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    primary: {},
    icon: {},
});

class Account extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    konversationen: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    selectKonversation: PropTypes.any.isRequired
  };

  constructor() {
    super();

    this.state = {
      account: {
        name: "N/A",
        email: "N/A"
      },
      konversationen: [],
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { account, konversationen, classes } = this.props;

    const open = Boolean(this.state.anchorEl);

    return (
      <div>
        <MenuItem>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary={account.name}
          />
          <IconButton
            aria-label="More"
            aria-owns={open ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem key="Eigenschaften" onClick={this.handleClose}>
              Eigenschaften
            </MenuItem>
            <Divider />
            <MenuItem key="Löschen" onClick={this.handleClose}>
              Löschen
            </MenuItem>
          </Menu>
        </MenuItem>
        <Konversationen
          konversationen={konversationen}
          account={account}
          selectKonversation={this.props.selectKonversation}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Account);