import React, { Component } from "react";
import Message from "../Message/Message";

import PropTypes from "prop-types";

import "./MessageList.css";

class MessageList extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired
  };

  render() {
    const { messages } = this.props;

    return (
      <div className="MessageList">
        {messages == null || messages.length === 0
          ? `keine Nachrichten`
          : messages.map((message, key) => <Message key={key} {...message} />)}
      </div>
    );
  }
}

export default MessageList;
