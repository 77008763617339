import React, { Component } from "react";

import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import uuidv4 from "uuid/v4";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import MessageList from "../MessageList/MessageList";
import MessageInput from "../MessageInput/MessageInput";
import Account from "../Account/Account";

const drawerWidth = 240;

const styles = theme => ({
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "fit-content",
    marginLeft: 0,
    width: "100%",
    backgroundColor: "#fdfdfd",
    padding: "5px",

    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    height: "60px",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flex: 1,
    flexGrow: 1,
    left: 0,
    top: "60px",
    width: "100%",
    height: "100%",
    padding: theme.spacing.unit * 3,
    marginBottom: "100px",

    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  }
});

const testdaten = [
  {
    account: { name: "geschäftlich" },
    konversationen: [
      {
        id: uuidv4(),
        name: "Projekt 1",
        nachrichten: [
          {
            id: uuidv4(),
            time: new Date().toLocaleString(),
            status: "gesendet",
            richtung: "eingang",
            sender: {
              shortName: "MH",
              name: "Heuser",
              firstName: "Martin"
            },
            message:
              "Hier kommt der gesamte Text hin. Ggf. können auch Absätze dargestellt werden."
          },
          {
            id: uuidv4(),
            time: new Date().toLocaleString(),
            status: "gesendet",
            richtung: "eingang",
            sender: {
              shortName: "MH",
              name: "Heuser",
              firstName: "Martin"
            },
            message: "Eine sehr kurze Nachricht."
          },
          {
            id: uuidv4(),
            time: new Date().toLocaleString(),
            status: "gesendet",
            richtung: "empfangen",
            sender: {
              shortName: "KD",
              name: "Deeley",
              firstName: "Kate"
            },
            message: "Was auch immer geschrieben wurde."
          },
          {
            id: uuidv4(),
            time: new Date().toLocaleString(),
            status: "gesendet",
            richtung: "eingang",
            sender: {
              shortName: "MH",
              name: "Heuser",
              firstName: "Martin"
            },
            message: `Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text.
Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text.
Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text. Eine ganz lange Nachricht mit viel Text.`
          }
        ]
      }
    ]
  },
  {
    account: { id: uuidv4(), name: "privat", nachrichten: [] },
    konversationen: [
      { id: uuidv4(), name: "Familie", nachrichten: [] },
      { id: uuidv4(), name: "Karel", nachrichten: [] }
    ]
  }
];

class Home extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  constructor() {
    super();

    this.state = {
      messages: [],
      accounts: []
    };

    setTimeout(() => {
      this.getAllAccounts().then(result => this.setState({ accounts: result }));
    }, 1000);
  }

  getSender = () => {
    return {
      shortName: "MH",
      name: "Heuser",
      firstName: "Martin"
    };
  };

  createNewMessage(message_text) {
    return {
      id: uuidv4(),
      time: new Date().toLocaleString(),
      status: "gesendet",
      richtung: "ausgang",
      sender: this.getSender(),
      message: message_text
    };
  }

  addMessage = message => {
    const isEndOfList =
      window.pageYOffset + window.outerHeight > document.body.scrollHeight;

    const new_message = this.createNewMessage(message);

    this.currentKonversation.nachrichten = [
      ...this.currentKonversation.nachrichten,
      new_message
    ];

    this.setState(
      {
        messages: this.currentKonversation.nachrichten
      },
      () => {
        if (isEndOfList) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  selectKonversation = (account, konversation) => {
    this.currentKonversation = konversation;
    this.setState(
      {
        messages: konversation.nachrichten,
        mobileOpen: false,
        konversation: this.currentKonversation,
        account: account
      },
      () => {
        window.scrollTo(0, document.body.scrollHeight);
      }
    );
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  /* eslint-disable */

  getAllAccounts = () => {
    console.log(navigator.serviceWorker.controller);
    return new Promise(function(resolve) {
      if (self.$) {
        $.getJSON("/getAllAccounts.json", resolve);
      } else if (self.fetch) {
        fetch("/getAllAccounts.json")
          .then(function(response) 
          {
            console.log(response);
            return response.json();
          })
          .then(function(reservations) {
            resolve(reservations);
          });
      }
    });
  };

  /* eslint-enable */

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <div>
          {this.state.accounts.map((account, key) => (
            <Account
              key={key}
              {...account}
              selectKonversation={this.selectKonversation}
            />
          ))}
        </div>
        <Divider />
        <List>
          <ListItem button key="Einstellungen">
            <ListItemIcon>
              <Icon>settings</Icon>
            </ListItemIcon>
            <ListItemText primary="Einstellungen" />
          </ListItem>
          <ListItem button key="Info">
            <ListItemIcon>
              <Icon>info</Icon>
            </ListItemIcon>
            <ListItemText primary="Info" />
          </ListItem>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <div>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" noWrap>
                {this.state.account ? this.state.account.name : "-"}/
                {this.state.konversation ? this.state.konversation.name : "-"}
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main>
            <div className={classes.content}>
              <MessageList messages={this.state.messages} />
            </div>
            <div className={classes.footer}>
              <MessageInput addMessage={this.addMessage} />
            </div>
          </main>
        </div>
        <div />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Home);
