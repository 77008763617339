import React, { Component } from "react";

import { TextField, IconButton, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";

import PropTypes from "prop-types";

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

class MessageInput extends Component {
  static propTypes = {
    addMessage: PropTypes.any.isRequired
  };

  constructor(props) {
    super();

    this.textInput = React.createRef();

    this.state = {
      message: "",
      disabled: true
    };

    this.addMessage = props.addMessage;
  }

  handleOnAction = e => {
    this.addMessage(this.state.message);
    this.setState({ message: "", disabled: true });

    if (this.textInput) {
      setTimeout(() => this.textInput.focus(), 100);
    }
  };

  handleOnChange = e => {
    const {
      target: { value, name }
    } = e;

    this.setState({
      [name]: value,
      disabled: value === undefined || value.length === 0
    });
  };

  render() {
    return (
        <Grid container >
          <Grid item xs>
            <TextField
              name="message"
              id="outlined-uncontrolled"
              label="Nachricht"
              margin="normal"
              variant="filled"
              multiline
              fullWidth
              rowsMax="5"
              value={this.state.message}
              onChange={this.handleOnChange}
              autoFocus
              inputRef={input => {
                this.textInput = input;
              }}
            />
          </Grid>
          <Grid item>
              <IconButton
                onClick={this.handleOnAction}
                color="primary"
                disabled={this.state.disabled}
              >
                <SendIcon />
              </IconButton>
          </Grid>
        </Grid>
    );
  }
}

export default withStyles(styles)(MessageInput);
